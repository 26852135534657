<template>
	<div class="position-relative text-center d-flex align-items-center justify-content-center mx-auto" style="width: 180px; height: 180px;">
		<div>
			<img src="@/assets/image/auto-compras2.webp" class="img-fluid">
			<div class="text-primary font-weight-bold">
				Cargando vehículos...
			</div>
		</div>
		<div class="loader-border-colors">
		</div>
	</div>
</template>